<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="3" md="3">
                <v-autocomplete
                  label="Bulan"
                  v-model="filter.bulan"
                  :items="bulans"
                  dense
                  item-text="text"
                  item-value="value"
                  outlined
                  @change="optionChange"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" md="2">
                <v-text-field
                  type="number"
                  label="Tahun"
                  v-model="filter.tahun"
                  dense
                  outlined
                  @keyup="optionChange"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" small @click="optionChange()"> Lihat </v-btn>
                <v-btn color="primary" class="ml-2" small @click="cetak"> Cetak </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <br />
          <div id="printMe">
            <table cellpadding="0" cellspacing="0" class="header">
              <tr>
                <td style="width: 20%">
                  <img style="width: 150px; height: auto" :src="require('@/assets/images/logos/logo.png')" />
                </td>
                <td style="width: 80%">
                  <center>
                    <span v-if="kop.kop_1 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_1 }} <br
                    /></span>
                    <span v-if="kop.kop_2 != ''" style="font-size: 12pt; font-weight: bold"
                      >{{ kop.kop_2 }} <br
                    /></span>
                    <span v-if="kop.kop_3 != ''" style="font-size: 10pt">{{ kop.kop_3 }} ><br /></span>
                    <span v-if="kop.kop_4 != ''" style="font-size: 10pt">{{ kop.kop_4 }} ><br /></span>
                  </center>
                </td>
                <td style="width: 20%">&nbsp;</td>
              </tr>
            </table>
            <h3 style="text-align: center">Laporan Bunga Tabungan Bulan {{ getNamaBulan() }} {{ filter.tahun }}</h3>
            <table cellpadding="0" cellspacing="0" class="body">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Kode</th>
                  <th>Nama</th>
                  <th>Jenis Kelamin</th>
                  <th>Tgl Lahir</th>
                  <th>Jenis Upah</th>
                  <th>Bagian</th>
                  <th>Wilayah</th>
                  <th>Bunga Tabungan</th>
                  <th>Total Tabungan</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in desserts" :key="index">
                  <td style="text-align: center">{{ index + 1 }}</td>
                  <td>{{ item.kode_anggota }}</td>
                  <td>{{ item.nama_anggota }}</td>
                  <td>{{ item.jenis_kelamin }}</td>
                  <td>{{ formatDate(item.tgl_lahir) }}</td>
                  <td>{{ item.jenis_upah }}</td>
                  <td>{{ item.divisi }}</td>
                  <td>{{ item.wilayah }}</td>
                  <td style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(item.besar_bunga) }}
                  </td>
                  <td style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(item.besar_tabungan) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="8" style="text-align: center">TOTAL BUNGA</td>
                  <td style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(footer.totalBunga) }}
                  </td>
                  <td style="text-align: right"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import '@/styles/print.css'
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  data: () => ({
    desserts: [],
    loading: true,
    output: null,
    kop: {},
    filter: {
      tahun: parseFloat(new Date().toJSON().slice(0, 4)),
      bulan: parseFloat(new Date().toJSON().slice(5, 7)),
    },
    bulans: [
      {
        text: 'Januari',
        value: 1,
      },
      {
        text: 'Februari',
        value: 2,
      },
      {
        text: 'Maret',
        value: 3,
      },
      {
        text: 'April',
        value: 4,
      },
      {
        text: 'Mei',
        value: 5,
      },
      {
        text: 'Juni',
        value: 6,
      },
      {
        text: 'Juli',
        value: 7,
      },
      {
        text: 'Agustus',
        value: 8,
      },
      {
        text: 'September',
        value: 9,
      },
      {
        text: 'Oktober',
        value: 10,
      },
      {
        text: 'November',
        value: 11,
      },
      {
        text: 'Desember',
        value: 12,
      },
    ],
    footer: {
      totalBunga: 0,
    },
  }),

  computed: {
    token() {
      return this.$store.getters.token
    },
  },

  watch: {},

  created() {
    this.getParameterKop()
    this.getDataFromApi()
  },

  methods: {
    async cetak() {
      // Pass the element id here
      await this.$htmlToPaper('printMe', {})
    },
    getParameterKop() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Dashboard/getParameterKop`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.kop = response.data.data.parameter
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getDataFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('bulan', this.filter.bulan)
      form.append('tahun', this.filter.tahun)
      axios
        .post(`${apiRoot}/api/Laporan/laporan_bungatabungan`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            response.data.data.forEach(item => {
              this.footer.totalBunga += parseFloat(item.besar_bunga)
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    optionChange() {
      // if (this.filter.tanggal_mulai != '' && this.filter.tanggal_akhir != '') {
      this.getDataFromApi()
      // }
    },
    getNamaBulan() {
      let bulan = ''
      this.bulans.forEach(element => {
        // console.log(element)
        if (element.value === this.filter.bulan) {
          // console.log('ok')
          bulan = element.text
        }
      })

      return bulan
    },
  },
}
</script>
